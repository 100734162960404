import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Col, Form, Input, Row, Typography } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PhoneNumberInput from "../../../../../components/form/phoneNumberInput/PhoneNumberInput";
import Button from "../../../../../components/ui/button/Button";
import useSignupContext, { FieldType } from "../services/signupContext";

const Signup = () => {
  const { t } = useTranslation();

  const [isPhoneCompleted, setIsPhoneCompleted] = useState(false);
  const [isAvailableOtp, setIsAvailableOtp] = useState(false);

  const {
    state: { isSentSms, phoneValidation, form },
    actions: {
      onFinish,
      onFinishFailed,
      validatePassword,
      validateConfirmPassword,
      sendOtp,
      setPhoneValidation,
      checkPhoneNumber,
      checkOtp,
    },
  } = useSignupContext();

  return (
    <Form
      name="auth.signup"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Row gutter={[20, 0]}>
        <Col xs={24} sm={12}>
          <Form.Item<FieldType>
            name="first_name"
            label={t("auth.signup.first_name")}
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item<FieldType>
            name="email"
            label={t("auth.signup.mail")}
            rules={[{ required: true, type: "email" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item<FieldType>
            name="phone_number"
            label={t("auth.signup.phone_number")}
            required
            rules={[
              {
                validator: async (_, value) => {
                  if (isPhoneCompleted) {
                    if (await checkPhoneNumber(value)) {
                      setIsAvailableOtp(true);
                      return Promise.resolve();
                    } else {
                      setIsAvailableOtp(false);
                      return Promise.reject(
                        new Error(t("auth.signup.exist_phone_number"))
                      );
                    }
                  } else {
                    setIsAvailableOtp(false);
                    return Promise.reject(
                      new Error(t("auth.signup.validation_phone_number"))
                    );
                  }
                },
              },
            ]}
          >
            <PhoneNumberInput
              form={form}
              name="phone_number"
              onChange={(value, data, e, f, isCompleted) =>
                setIsPhoneCompleted(isCompleted)
              }
              disabled={isSentSms}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item<FieldType>
            name="otp"
            label={t("auth.signup.confirmation_code")}
            required
            rules={[
              {
                validator: async (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      new Error(t("auth.signup.validation_confirmation_code"))
                    );
                  } else {
                    if (await checkOtp(Number(value))) {
                      setPhoneValidation(true);
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          t(
                            "auth.signup.validation_confirmation_code_not_match"
                          )
                        )
                      );
                    }
                  }
                },
              },
            ]}
            dependencies={["phone_number"]}
          >
            {isSentSms && (
              <Input.OTP
                size="large"
                style={{ width: "100%" }}
                length={4}
                disabled={phoneValidation}
              />
            )}
            {!isSentSms && (
              <Button
                type="primary"
                size="large"
                block
                disabled={!isAvailableOtp}
                onClick={sendOtp}
              >
                {t("auth.signup.get_confirmation_code")}
              </Button>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item<FieldType>
            name="password"
            label={t("auth.signup.password")}
            rules={[{ required: true, validator: validatePassword }]}
          >
            <Input size="large" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item<FieldType>
            name="confirm_password"
            label={t("auth.signup.confirmation_password")}
            rules={[validateConfirmPassword]}
            dependencies={["password"]}
          >
            <Input size="large" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item<FieldType>
            name="remember"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => {
                  if (value) return Promise.resolve();
                  else
                    return Promise.reject(
                      new Error(t("auth.signup.validation_agreement"))
                    );
                },
              },
            ]}
          >
            <Checkbox>
              {t("auth.signup.agreement")
                .split(/({terms_of_use}|{privacy_policy})/)
                ?.map((part, index) => {
                  if (part === "{terms_of_use}") {
                    return (
                      <Link key={index} to="/terms-of-use">
                        {t("auth.signup.terms_of_use")}
                      </Link>
                    );
                  }
                  if (part === "{privacy_policy}") {
                    return (
                      <Link key={index} to="/privacy-policy">
                        {t("auth.signup.privacy_policy")}
                      </Link>
                    );
                  }
                  return part;
                })}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          icon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
          block
        >
          {t("auth.signup.submit")}
        </Button>
      </Form.Item>
      <div>
        <Typography.Paragraph
          type="secondary"
          style={{ textAlign: "center", margin: 0 }}
        >
          {t("auth.signup.has_account")}{" "}
          <Link to="/login">{t("auth.signup.login")}</Link>
        </Typography.Paragraph>
      </div>
    </Form>
  );
};

export default Signup;
